import loadable from '@loadable/component'
import React from 'react'
import { Link } from 'gatsby'
import config from '../../config'
import Helmet from 'react-helmet'
import Layout from '../components/Layout'
import SE0 from '../components/SEO'

const OfferRoll = loadable(() => import('../components/OfferRoll'))
const ContactCall = loadable(() => import('../components/ContactCall'))

const OfertaPage = (props) => {
  const { pageContext: { first, group, index, last } } = props
  const previousUrl = index - 1 === 1 ? '' : (index - 1).toString()
  const nextUrl = (index + 1).toString() + '/'

  const websiteSchemaOrgJSONLD = {
    '@context': 'http://schema.org',
    '@type': 'WebSite',
    url: config.siteUrl,
    name: config.siteTitle,
    alternateName: config.siteTitleAlt ? config.siteTitleAlt : '',
  }

  return (
    <Layout>
    <SE0
      title="Offer 🌱 Art Open Sp. z o.o. | Advertising agency "
      meta_title="Offer 🌱 Art Open Sp. z o.o. | Advertising agency "
      meta_desc="Advertising agency | Art Open 🌳 covid-19, ecology, gadgets, branding, websites and online shops, e-commerce, graphic design, VR and 3D, calendars."
      keywords="advertising agency, branding, websites, online shops, applications, ecology, e-commerce, positioning, seo, graphic design, book calendars, gadgets, prints, advertising, photography, filming, covid-19, surgical masks, masks with logo, exhibition, packaging, vr, 3D"
      slug="/offer/"
      cover="/img/oferta.svg"
      siteTitleAlt={config.siteTitleAlt}
      userName={config.userName}
      siteTitle={config.siteTitle}
      siteUrl={config.siteUrl}
      siteFBAppID={config.siteFBAppID}
      userTwitter={config.userTwitter}
      pathPrefix={config.pathPrefix}
    />
      <Helmet htmlAttributes={{ lang : 'en-en', dir:'ltr' }}>
        {/* Schema.org tags */}
        <script type='application/ld+json'>
          {JSON.stringify(websiteSchemaOrgJSONLD)}
        </script>

        <script type='application/ld+json'>
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          "itemListElement": [{
            "@type": "ListItem",
            "position": 1,
            "name": "Home",
            "item": "https://artopen.co/"
          },{
            "@type": "ListItem",
            "position": 2,
            "name": "Offer",
          }]
        })}
        </script>

      </Helmet>
      <section className='hero is-primary is-bold' id="off-head" style={{minHeight:'70%',backgroundImage:'url(/img/oferta.svg)',backgroundSize:'contain',backgroundRepeat:'no-repeat',backgroundPosition:'top center',backgroundAttachment: `fixed`}}>
        <div className='hero-body'>
        <div className='container section'>
          <div className='columns section'>
            <div>
              <h1 className='title' style={{fontSize:'50px'}}>
                Offer
              </h1>
              <h2 className='subtitle'>
              Find out about our qualifications.
              </h2>
            </div>
          </div>
        </div>
        </div>
      </section>

      <nav className="breadcrumbs-nav section">
      <Link className="inline-link" to="/">Home</Link>&nbsp;&gt;&nbsp;<span style={{color:'#23C495'}}><b>Offer</b></span>
      </nav>

      <div style={{textAlign:'center',marginRight:'15px',marginLeft:'15px',marginTop:'-50px'}}>
      <br></br>

      <img alt="Art Open advertising agency" id='rotate' src="/img/logo-square.svg" width="120px" />
      <br />

      <h2 className='title'>
      Scope of our <b>activities</b>:
      </h2>
      <h3 className='subtitle'>We invite you to find out more about the services we offer.</h3>

      </div>
      <section className='section' style={{padding: '0px', margin:'0px',marginTop:'50px'}}>
        <OfferRoll />
        <br />
        <div className="section" style={{marginLeft:'5%',marginRight:'5%',textAlign:'left',padding:'5%'}}>
        <p className="title">We provide a comprehensive service for your company, project or brand by offering the above services.</p>
        <br />
        <p className="subtitle" style={{paddingLeft:'10px',borderLeft:'10px solid #23C495'}}><Link className="inline-link" to="/about-us/">Our activity </Link> doesn’t end with what we described previously. As an advertising agency, we engage in various types of  <Link className="inline-link" to="/blog/29-wosp-final/">charity campaigns</Link>,
        <Link className="inline-link" to="/blog/gaszyn-challange-charity-action/"> fundraisings</Link>, <Link className="inline-link" to="/blog/29-wosp-final/">social assistance</Link>, and various types of <Link className="inline-link" to="/blog/animals-in-need/">animal help</Link>. We also care for the environment by organizing <Link className="inline-link" to="/blog/cleaning-action-open-for-environment-v-2/">public cleaning campaigns</Link>. If you’re interested in learning more about our activities for the benefit of society, animals and the environment, you can find information about our actions and campaigns on the <Link className="inline-link" to="/blog/">blog </Link>, or by browsing the <Link className="inline-link" to="/portfolio/">portfolio</Link>. In our projects, we use <Link className="inline-link" to="/offer/web-pages/#technologies">advanced technologies</Link> chosen to meet our customers’ needs and requirements. We would also like to encourage you to use our <Link className="inline-link" to="/calendars-creator/">book calendar wizard</Link> or <Link className="inline-link" to="/quote-art-open/">fill in the service quote form</Link>.
        </p>
        </div>
        <br />
        <br />
        <div className='bottomUi' style={{textAlign:'center',marginBottom:'60px'}}>
        <Link className='button-green' style={{fontSize:'15px'}} to='/portfolio/'> See portfolio&nbsp;&nbsp; <img width="12px"  style={{verticalAlign:'middle', marginRight:'5px'}}   alt="arrow" src='/img/angle-right.svg'/> </Link>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <Link className='button-green' style={{fontSize:'15px'}} to='/blog/'> Read the blog&nbsp;&nbsp; <img width="12px"  style={{verticalAlign:'middle', marginRight:'5px'}}   alt="arrow" src='/img/angle-right.svg'/> </Link>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <Link className='button-green' style={{fontSize:'15px'}} to='/about-us/'> Get to know us better&nbsp;&nbsp; <img width="12px"  style={{verticalAlign:'middle', marginRight:'5px'}}   alt="arrow" src='/img/angle-right.svg'/> </Link>
        </div>

        <div style={{textAlign:'center',paddingTop:'50px'}}>
        <br />
        <h3 style={{fontSize:'18px'}}> Fill in our <Link className="inline-link" to="/quote-art-open/"><b>form</b></Link></h3>
        <br />
        <h4 style={{fontSize:'14px',marginLeft:'20%',marginRight:'20%'}}>Answer the questions and get free service pricing. In our offer you will find many services, such as <Link className="inline-link" to='/offer/graphic-design/'>graphic design</Link>, personalized <Link className="inline-link" to='/offer/book-calendars/'>book calendars</Link>, <Link className="inline-link" to='/offer/company-calendars/'>company calendars</Link>, <Link className="inline-link" to='/offer/ecology/'>ecological calendars</Link> <Link className="inline-link" to='/offer/gadgets/'>advertising gadgets</Link>, <Link className="inline-link" to='/offer/printouts/'>printouts</Link>, <Link className="inline-link" to='/offer/filming/'>advertising movies</Link> and <Link className="inline-link" to='/offer/photography/'>advertising photos</Link> as well as <Link className="inline-link" to='/offer/web-pages/'>website programming, including e-commerce, applications, </Link> or <Link className="inline-link" to='/offer/vr/'>AR and VR systems.</Link></h4>
        <br />
        <br />
        <Link className='button-green' to="/quote-art-open/"> get a quote &nbsp;&nbsp;
        <img width="10px"  style={{verticalAlign:'middle', marginRight:'5px'}}   alt="ecology" src='/img/leaf.svg'/>
         </Link>
        <br />
        <br />
        <br />
        <br />
        </div>
        <div style={{padding:'0px',marginBottom:'-10px'}}>
        <iframe loading="lazy" style={{borderRadius:'0px'}} width="100%" height="612" defer src="https://iris-mesquite-snowshoe.glitch.me" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </div>
        <ContactCall/>
      </section>
    </Layout>
  )
}

export default OfertaPage
